module.exports = {
    'title': '编辑个人资料信息',
    'label_salutation': '称呼',
    'label_firstname': '名字',
    'label_lastname': '姓氏',
    'label_email': '邮件',
    'label_gender': '性别',
    'gender_type_male': '男性',
    'gender_type_female': '女性',
    'label_birthdate': '生日',
    'label_day': '日',
    'label_month': '月',
    'label_year': '年',
    'label_mobile_phone': '手机号码',
    'label_slectbox': '请选择',
    'label_nationality': '国籍',
    'label_country_of_residence': '居住国家',
    'label_language': '首选语言',
    'label_address': '邮寄地址',
    'terms_description': '我想接受阿雅娜会员优享计划的新闻和促销信息',
    'monthly_sub_description': '我想收到会员月度账户汇总',
    'label_btn_submit': '保存更改'
}