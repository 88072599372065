import React, { Component, useEffect } from "react";
import { Link, navigate } from "gatsby";

import Layout from "../../layouts/zh";
import SEO from "../../components/seo";
import EditProfile from "../../services/ChangeProfile";
import { isLoggedIn } from "../../services/Auth";
import { setUserData } from "../../data/setUserData";
import langData from "../../data/static-pages/pages/member/change-profile/zh";

export default class ChangeProfilePage extends Component {
  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    this.checkLoginStatus();
  }

  checkLoginStatus() {
    if (isLoggedIn() == false) navigate("/zh/login");
  }

  render() {
    const userData = setUserData();

    return (
      <Layout location={this.props.location}>
        <SEO
          title={"Profile " + userData?.firstname}
          keyword="Profile Member "
          decription="Profile Member "
        />
        <EditProfile
          langData={langData}
          langkey={"zh"}
          location={this.props.location}
        />
      </Layout>
    );
  }
}
